<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <Filters ref="filters" @on-apply-click="onFiltersApplyClick" />

            <DxDataGrid
              id="gvDebtors"
              ref="gvDebtors"
              v-bind="options"
              width="100%"
              style="max-width: 100%"
              :data-source="debtorsData"
              :word-wrap-enabled="true"
              :column-auto-width="true"
              @toolbar-preparing="onToolbarPreparing($event)"
              @content-ready="onContentReady"
            >
              <DxFilterRow :visible="true" />
              <DxHeaderFilter :visible="true" :allow-search="true" />
              <DxStateStoring :enabled="true" type="local" storage-key="gvDebtors_2" />
              <DxSorting mode="multiple"/>

              <DxColumn data-field="streetName" caption="Улица" width="180" sort-order="asc" :sort-index="0" />
              <DxColumn
                data-field="houseNumber"
                caption="Дом"
                width="60"
                sort-order="asc"
                :sort-index="1"
                :allow-header-filtering="false"
              />
              <DxColumn
                data-field="buildingNumber"
                caption="Корп."
                width="60"
                sort-order="asc"
                :sort-index="2"
                :allow-header-filtering="false"
              />
              <DxColumn data-field="flatNumber" caption="Кв." width="60" :allow-header-filtering="false" />
              <DxColumn
                data-field="accountNumber"
                caption="ЛС"
                width="100"
                cell-template="accountNumberTemplate"
                :allow-header-filtering="false"
              />
              <DxColumn
                data-field="oldAccountNumber"
                caption="Старый ЛС"
                width="100"
                cell-template="accountNumberTemplate"
                :allow-header-filtering="false"
              />
              <DxColumn data-field="name" caption="ФИО" width="150" :allow-header-filtering="false" />
              <DxColumn data-field="flatType" caption="Тип" width="50" />
              <DxColumn
                data-field="lastWritFilingDate"
                caption="Дата подачи последнего иска"
                data-type="date"
                width="80"
                :allow-header-filtering="false"
              />
              <DxColumn
                data-field="maxWritPreparationPeriodEnd"
                caption="Последний просуженный период"
                data-type="date"
                width="80"
                :allow-header-filtering="false"
              />
              <DxColumn data-field="lastWritAuthorUserId" caption="Автор последнего иска" :allow-header-filtering="false">
                <DxLookup :data-source="usersStore" display-expr="name" value-expr="id" />
              </DxColumn>
              <DxColumn data-field="lastWritState" caption="Этап последнего иска" width="80" :allow-header-filtering="false">
                <DxLookup :data-source="writStatesStore" display-expr="name" value-expr="id" />
              </DxColumn>

              <DxColumn data-field="debtsAll" caption="Долг всего" width="100" :allow-header-filtering="false">
                <DxFormat type="fixedPoint" :precision="2" />
              </DxColumn>
              <DxColumn data-field="debtsAllPrevious" caption="Долг всего предыдущий" width="100" :allow-header-filtering="false">
                <DxFormat type="fixedPoint" :precision="2" />
              </DxColumn>
              <DxColumn data-field="monthAll" caption="Кол-во мес. всего" width="80" :allow-header-filtering="false" />
              <DxColumn data-field="monthAllPrevious" caption="Кол-во мес.всего предыдущее" width="80" :allow-header-filtering="false" />

              <DxColumn data-field="debtsOpened" caption="Долг ОЛС" width="100" :allow-header-filtering="false">
                <DxFormat type="fixedPoint" :precision="2" />
              </DxColumn>
              <DxColumn data-field="monthOpened" caption="Кол-во мес. ОЛС" width="80" :allow-header-filtering="false" />

              <DxColumn data-field="debtsClosed" caption="Долг ЗЛС" width="100" :allow-header-filtering="false">
                <DxFormat type="fixedPoint" :precision="2" />
              </DxColumn>
              <DxColumn data-field="monthClosed" caption="Кол-во мес. ЗЛС" width="80" :allow-header-filtering="false" />

              <DxColumn data-field="constantCharges" caption="Начислено в посл.мес." width="100" :allow-header-filtering="false">
                <DxFormat type="fixedPoint" :precision="2" />
              </DxColumn>
              <DxColumn data-field="payments" caption="Оплачено в посл.мес." width="100" :allow-header-filtering="false">
                <DxFormat type="fixedPoint" :precision="2" />
              </DxColumn>

              <DxColumn
                data-field="periodsClosed"
                caption="Периоды ЗЛС"
                :customize-text="makePeriodsOpened"
                :encode-html="false"
                :allow-header-filtering="false"
              />
              <DxColumn
                data-field="periodsOpened"
                caption="Периоды ОЛС"
                :customize-text="makePeriodsClosed"
                :encode-html="false"
                :allow-header-filtering="false"
              />

              <!-- Templates -->
              <template #accountNumberTemplate="{ data }">
                <router-link :to="{ name: 'debtor-card', params: { id: data.data.id } }">
                  {{ data.value }}
                </router-link>
              </template>

              <DxSummary>
                <DxTotalItem column="constantCharges" summary-type="sum" valueFormat="#,##0.##" display-format="Сумма: {0}" />
                <DxTotalItem column="payments" summary-type="sum" valueFormat="#,##0.##" display-format="Сумма: {0}" />
                <DxTotalItem column="debtsAll" summary-type="sum" valueFormat="#,##0.##" display-format="Сумма: {0}" />
                <DxTotalItem column="debtsAllPrevious" summary-type="sum" valueFormat="#,##0.##" display-format="Сумма: {0}" />
                <DxTotalItem column="debtsOpened" summary-type="sum" valueFormat="#,##0.##" display-format="Сумма: {0}" />
                <DxTotalItem column="debtsClosed" summary-type="sum" valueFormat="#,##0.##" display-format="Сумма: {0}" />
              </DxSummary>
              <DxColumnChooser :enabled="true" title="Выбор столбцов" mode="select" />
              <DxExport fileName="Журнал_должников" :enabled="true" />
              <DxScrolling column-rendering-mode="virtual" :use-native="true" show-scrollbar="true" />
              <DxPaging :page-size="20" :enabled="true" />
              <DxPager
                :visible="true"
                :allowed-page-sizes="[5, 10, 20, 50]"
                :show-page-size-selector="true"
                :show-navigation-buttons="true"
                :show-info="true"
              />
            </DxDataGrid>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";

import { usersStore, writStatesStore } from "@/helpers/common-store/store";
import createStoreExtend from "@/helpers/grid/store";
import settings from "@/helpers/grid/settings.js";
import Filters from "@/components/debtors/filters.vue";

import {
  DxSummary,
  DxTotalItem,
  DxFormat,
  DxFilterRow,
  DxHeaderFilter,
  DxScrolling,
  DxColumnChooser,
  DxStateStoring,
  DxExport,
  DxSorting
} from "devextreme-vue/data-grid";
import { debtorsFiltersComputed, authComputed } from "@/state/helpers";

export default {
  components: {
    Layout,
    PageHeader,
    DxSummary,
    DxTotalItem,
    DxFormat,
    DxFilterRow,
    DxHeaderFilter,
    DxScrolling,
    DxColumnChooser,
    Filters,
    DxStateStoring,
    DxExport,
    DxSorting
  },
  computed: {
    ...debtorsFiltersComputed,
    ...authComputed,
  },
  watch: {
    currentFilters: {
      deep: true,
      handler() {
        this.onFiltersApplyClick();
      },
    },

    isFiltersEmpty: {
      immediate: false,
      handler() {
        this.toggleResetFilters();
      },
    },
  },
  mounted() {
    this.isFiltersEmpty = this.$refs.filters.isEmptyFilters();
    this.toggleResetFilters();
  },
  data() {
    return {
      usersStore,
      writStatesStore,
      title: "Должники",
      items: [
        {
          text: "Главная",
          href: "/",
        },
        {
          text: "Должники",
          active: true,
        },
      ],
      isFiltersEmpty: true,
      filters: null,
      debtorsData: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_DEBTORS_GET}`,
        onBeforeSend: (method, ajaxOptions) => {
          var self = this;
          if (this.currentFilters)
            Object.keys(this.currentFilters).forEach(function (key) {
              ajaxOptions.data[key] = self.currentFilters[key];
            });
        },
      }),
      options: settings,
    };
  },
  methods: {
    onToolbarPreparing(e) {
      let toolbarItems = e.toolbarOptions.items;
      console.log(toolbarItems);
      let chooserColumn = toolbarItems.filter((r) => r.name == "columnChooserButton")[0];
      let self = this;
      chooserColumn.location = "before";
      chooserColumn.showText = "always";
      chooserColumn.options.icon = "bx bx-list-ul";
      chooserColumn.options.elementAttr = {
        class: "b secondary",
      };

      const exportBtnItem = toolbarItems.find((item) => item.name === "exportButton");
      const exportBtnIndex = toolbarItems.indexOf(exportBtnItem);
      toolbarItems[exportBtnIndex] = {
        location: "after",
        widget: "dxButton",
        visible: this.hasRightExport,
        options: {
          text: "Выгрузить в файл",
          // icon: "export-excel-button",
          hint: "Export all data",
          elementAttr: {
            class: "dx-datagrid-export-button",
          },
          onClick: function () {
            e.component.exportToExcel(false);
          },
        },
      };

      toolbarItems.push({
        widget: "dxButton",
        options: {
          // visible: false,
          // icon: "filter",
          icon: "mdi mdi-filter-off-outline",
          text: "Сбросить фильтры",
          onClick: function () {
            self.$refs["gvDebtors"].instance.clearFilter();
          },
          elementAttr: {
            class: "b secondary",
          },
        },
        location: "before",
      });

      toolbarItems.push({
        widget: "dxButton",
        options: {
          // visible: false,
          icon: "remove",
          elementAttr: {
            class: "b secondary",
            id: "btnResetFilters",
          },
          text: "Сбросить параметры",
          onClick: function () {
            self.resetFilters();
          },
        },
        location: "after",
      });

      toolbarItems.push({
        location: "after",
        template: function () {
          return `<button class="btn btn-primary btn-label right" style="height: 27px; padding: 2px 38px 4px 10px;"
data-bs-toggle="offcanvas"  href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
  <i class="ri-arrow-right-line label-icon align-middle fs-16"></i>Поиск по параметрам
</button>`;
        },
      });
    },
    onContentReady(e) {
      var columnChooserView = e.component.getView("columnChooserView");
      if (!columnChooserView._popupContainer) {
        columnChooserView._initializePopupContainer();
        columnChooserView.render();
        columnChooserView._popupContainer.option("position", { of: e.element, my: "left top", at: "left top", offset: "0 50" });
      }
    },
    makePeriodsOpened(data) {
      return this.makePeriods(data.value);
    },
    makePeriodsClosed(data) {
      return this.makePeriods(data.value);
    },
    makePeriods(data) {
      if (!data) return "";
      return data.replaceAll(",", "<br/>\r\n");
    },
    toggleResetFilters() {
      let btn = document.getElementById("btnResetFilters");
      if (this.isFiltersEmpty) btn.classList.add("d-none");
      else btn.classList.remove("d-none");
    },
    resetFilters() {
      this.$refs.filters.resetFilters();
    },
    onFiltersApplyClick(filters) {
      this.filters = filters;
      this.isFiltersEmpty = this.$refs.filters.isEmptyFilters();
      this.$refs["gvDebtors"].instance.refresh();
    },
  },
};
</script>

<style>
.dx-datagrid-headers {
  white-space: normal;
}

.offcanvas-start,
.offcanvas-end {
  width: 400px;
}
</style>
